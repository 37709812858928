import React, {Fragment} from 'react';
import { graphql , Link} from 'gatsby';

import CardMetaData from '../../components/cardMetaData';
import Layout from '../../components/layout';

import "./styles.css";
import { getCurrentMonth } from '../../utilities/utilfns';

function BankCards(props){
    const {data} = props;
    const allBankCards = data.allMongodbMmcnewCarddatas.nodes;
    const bankDetails = data.allMongodbMmcnewBankdatas.nodes[0];
    const bankName = allBankCards[0].bankId;
    return (
      <Layout>
        <main key={bankName} style={{margin : 0 , overflow : "hidden"}}>  
            <div  style={{ background: "#F1F7FF"}}> 
              <div className='bank-title-container'>
                <div className='bank-title-container-title'> {bankDetails.displayName} </div>
                <Link to={`/cards/${bankName}`}> {`Cards / ${bankDetails.displayName}`}  </Link> 
              </div>
            </div>
            <div style={{display:"flex" , alignItems : "center", justifyContent : "center", margin : "2rem 0"}}>
             <img style={{maxHeight : "10rem", maxWidth : "45%" , minHeight : "5rem"}} src={bankDetails.logo} alt={bankDetails.logo} />
            </div>
            <div className='bank-description'> { bankDetails.description.split("[EOL]").map( ele => <div style={{margin:"1rem auto"}}> {ele} </div> ) } </div>
            <section id="bank-card-list" className='bank-card-containter'> 
                {allBankCards.map( ele => <CardMetaData key={ele.key} cardDetails={ele} showFooter={true} /> )}
            </section>


        </main>
    </Layout>)

}


export default BankCards;

export const Head = ({data}) => {
  const bankDetails = data.allMongodbMmcnewBankdatas.nodes[0];
  const content ="Following are the credit cards that are offered by" + bankDetails.displayName ;
  const allBankCards = data.allMongodbMmcnewCarddatas.nodes.reduce( ( acc, ele) => {
    acc += ele.displayName + "\n";
    return acc;
  }  , "" );
  return (<Fragment>
      <title> Best {bankDetails.displayName + ` Credit Cards in India -  ${getCurrentMonth()} | Easy Apply` }    </title>
      <meta name="description"  content={content} />
  </Fragment>)
}


export const query = graphql`
query ($key: String) {
  allMongodbMmcnewBankdatas(filter : {key : {eq : $key}}){
    nodes {
      key
      displayName
      description
      id
      logo
      url
      rewardsPortal
    }
  }
  allMongodbMmcnewCarddatas(filter: {bankId: {eq: $key} }) {
    nodes {
      bankId
      key
      cardType
      cardCategory
      applyLinkUrl
      displayName
      imageUrl
      rating
      bestFor
      savings {
        details
        percentage
      }
      joiningAnnualAndMilestoneBenefits {
        joiningBenefit {
          shortDescription
          longDescription                
        }
      }
      feesAndCharges {
        annualFees
      }
      rewards {
        accerlatedRewards
        rewardsList {
          percentage
          text
        }
      }
      eligibility {
        minimumITR
      }
      features {
        loungeAccess {
          forCardHolders {
            domestic
            international
            railway
          }
        }
      }
    }
  }
}
`